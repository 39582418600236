import axios from 'axios';
import { PATH_AUTH } from '../routes/paths';
import { setSession } from './jwt';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401 && error.response.data === 'Unauthorized.') {
      setSession(null);
      window.location = PATH_AUTH.login;
    }

    return Promise.reject(error.response ? error.response : 'Something went wrong');
  }
);

export default axiosInstance;
