import { map, filter, concat } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: '',
  configurations: [],
  configuration: null
};

const slice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload.data.error.message;
    },

    // GET CONFIGRURATION
    getConfigurationSuccess(state, action) {
      state.isLoading = false;
      state.configuration = action.payload;
    },

    // GET CONFIGRURATIONS
    getConfigurationsSuccess(state, action) {
      state.isLoading = false;
      state.configurations = action.payload;
    },

    // CREATE CONFIGRURATION
    createConfigurationSuccess(state, action) {
      const addConfiguration = concat([action.payload], state.configurations);
      state.configurations = addConfiguration;
    },

    // UPDATE CONFIGRURATION
    updateConfigurationSuccess(state, action) {
      const updateConfiguration = map(state.configurations, (configuration) =>
        configuration.id === action.payload.id ? action.payload : configuration
      );
      state.configurations = updateConfiguration;
    },

    // DELETE CONFIGRURATION
    deleteConfigurationSuccess(state, action) {
      const deleteConfiguration = filter(
        state.configurations,
        (configuration) => configuration.id !== action.payload.id
      );
      state.configurations = deleteConfiguration;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getConfiguration(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/configurations/${id}`);
      dispatch(slice.actions.getConfigurationSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConfigurations() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/configurations');
      dispatch(slice.actions.getConfigurationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createConfiguration(configuration) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/configurations', configuration);
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateConfiguration(configuration) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/api/configurations/${configuration.id}`, configuration);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteConfiguration(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/configurations/${id}`);
      if (response.status === 200) dispatch(slice.actions.deleteConfigurationSuccess({ id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
