import { map, filter, concat } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  languages: [],
  language: null
};

const slice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LANGUAGE
    getLanguageSuccess(state, action) {
      state.isLoading = false;
      state.language = action.payload;
    },

    // GET LANGUAGES
    getLanguagesSuccess(state, action) {
      state.isLoading = false;
      state.languages = action.payload;
    },

    // CREATE LANGUAGE
    createLanguageSuccess(state, action) {
      const addLanguage = concat([action.payload], state.languages);
      state.languages = addLanguage;
    },

    // UPDATE LANGUAGE
    updateLanguageSuccess(state, action) {
      const updateLanguage = map(state.languages, (language) =>
        language.id === action.payload.id ? action.payload : language
      );
      state.languages = updateLanguage;
    },

    // DELETE LANGUAGE
    deleteLanguageSuccess(state, action) {
      const deleteLanguage = filter(state.languages, (language) => language.id !== action.payload.id);
      state.languages = deleteLanguage;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getLanguage(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/languages/${id}`);
      dispatch(slice.actions.getLanguageSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLanguages() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/languages');
      dispatch(slice.actions.getLanguagesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createLanguage(language) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/languages', language);
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateLanguage(language) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/api/languages/${language.id}`, language);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteLanguage(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/languages/${id}`);
      if (response.status === 200) dispatch(slice.actions.deleteLanguageSuccess({ id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
