import { map, filter, concat } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: false,
  categories: [],
  category: null
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CATEGORY
    getCategorySuccess(state, action) {
      state.isLoading = false;
      state.category = action.payload;
    },

    // GET CATEGORIES
    getCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    // CREATE CATEGORY
    createCategorySuccess(state, action) {
      const addCategory = concat([action.payload], state.categories);
      state.categories = addCategory;
    },

    // UPDATE CATEGORY
    updateCategorySuccess(state, action) {
      const updateCategory = map(state.categories, (category) =>
        category.id === action.payload.id ? action.payload : category
      );
      state.categories = updateCategory;
    },

    // DELETE CATEGORY
    deleteCategorySuccess(state, action) {
      const deleteCategory = filter(state.categories, (category) => category.id !== action.payload.id);
      state.categories = deleteCategory;
      state.isLoading = false;
    },

    // REORDER CATEGORY
    reorderCategorySuccess(state, action) {
      // console.log('Reorder Success: ', action);
      const reorderedCategories = map(state.categories, (category) =>
        category.id === action.payload.id ? { ...category, displayOrder: action.payload.pos } : category
      );
      state.categories = reorderedCategories;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteCategorySuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getCategory(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/categories/${id}`);
      dispatch(slice.actions.getCategorySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCategories() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/categories');
      dispatch(slice.actions.getCategoriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createCategory(category) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/categories', category);
    } catch (error) {
      // console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateCategory(category) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/categories/update', category);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteCategory(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/categories/${id}`);
      if (response.status === 200) dispatch(slice.actions.deleteCategorySuccess({ id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function reorderCategory(id, prePos, pos) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/categories/displayorder/${id}/${pos}`);
      if (response.status === 200)
        dispatch(slice.actions.reorderCategorySuccess({ id, pos: prePos < pos ? pos + 1 : pos - 1 }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
