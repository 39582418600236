import { map, filter, concat } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  seasons: [],
  season: null
};

const slice = createSlice({
  name: 'season',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET SEASONS
    getSeasonsSuccess(state, action) {
      state.isLoading = false;
      state.seasons = action.payload;
    },

    // GET SEASON
    getSeasonSuccess(state, action) {
      state.isLoading = false;
      state.season = action.payload;
    },

    // CREATE SEASON
    createSeasonSuccess(state, action) {
      const addSeason = concat([action.payload], state.seasons);
      state.isLoading = false;
      state.seasons = addSeason;
    },

    // UPDATE SEASON
    updateSeasonSuccess(state, action) {
      const updateSeason = map(state.seasons, (season) => (season.id === action.payload.id ? action.payload : season));
      state.isLoading = false;
      state.seasons = updateSeason;
    },

    // DELETE SEASON
    deleteSeasonSuccess(state, action) {
      const deleteSeason = filter(state.seasons, (season) => season.id !== parseInt(action.payload.id, 10));
      state.isLoading = false;
      state.seasons = deleteSeason;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getSeasons() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/seasons');
      dispatch(slice.actions.getSeasonsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSeasonsBySeriesId(seriesId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/seasons/byseries/${seriesId}`);
      dispatch(slice.actions.getSeasonsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSeason(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/seasons/${id}`);
      dispatch(slice.actions.getSeasonSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createSeason(season) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/seasons', season, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateSeason(season) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/seasons/update', season);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteSeason(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/seasons/${id}`);
      if (response.status === 200) dispatch(slice.actions.deleteSeasonSuccess({ id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
