import { map, filter, concat } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  episodes: [],
  episode: null
};

const slice = createSlice({
  name: 'episode',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EPISODES
    getEpisodesSuccess(state, action) {
      state.isLoading = false;
      state.episodes = action.payload;
    },

    // GET EPISODE
    getEpisodeSuccess(state, action) {
      state.isLoading = false;
      state.episode = action.payload;
    },

    // CREATE EPISODE
    createEpisodeSuccess(state, action) {
      const addEpisode = concat([action.payload], state.episodes);
      state.episodes = addEpisode;
    },

    // UPDATE EPISODE
    updateEpisodeSuccess(state, action) {
      const updateEpisode = map(state.episodes, (episode) =>
        episode.id === action.payload.id ? action.payload : episode
      );
      state.episodes = updateEpisode;
    },

    // DELETE EPISODE
    deleteEpisodeSuccess(state, action) {
      const deleteEpisode = filter(state.episodes, (episode) => episode.id !== action.payload.id);
      state.episodes = deleteEpisode;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getEpisodes() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/episodes');
      dispatch(slice.actions.getEpisodesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEpisodesBySeasonId(seasonId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/episodes/byseason/${seasonId}`);
      dispatch(slice.actions.getEpisodesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEpisode(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/episodes/${id}`);
      dispatch(slice.actions.getEpisodeSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createEpisode(episode) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/episodes', episode, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateEpisode(episode) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/episodes/update', episode);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteEpisode(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/episodes/${id}`);
      if (response.status === 200) dispatch(slice.actions.deleteEpisodeSuccess({ id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
