import { map, filter, concat } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  packages: [],
  cmsProducts: [],
  package: null
};

const slice = createSlice({
  name: 'package',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CMSPRODUCTS
    getCMSProductsSuccess(state, action) {
      state.isLoading = false;
      state.cmsProducts = action.payload;
    },

    // GET PACKAGES
    getPackagesSuccess(state, action) {
      state.isLoading = false;
      state.packages = action.payload;
    },

    // GET PACKAGE
    getPackageSuccess(state, action) {
      state.isLoading = false;
      state.package = action.payload;
    },

    // CREATE PACKAGE
    createPackageSuccess(state, action) {
      const addPackage = concat([action.payload], state.packages);
      state.packages = addPackage;
    },

    // UPDATE PACKAGE
    updatePackageSuccess(state, action) {
      const updatePackage = map(state.packages, (item) => (item.id === action.payload.id ? action.payload : item));
      state.packages = updatePackage;
    },

    // DELETE PACKAGE
    deletePackageSuccess(state, action) {
      const deletePackage = filter(state.packages, (item) => item.id !== action.payload.id);
      state.packages = deletePackage;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPackage(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/packages/${id}`);
      dispatch(slice.actions.getPackageSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createPackage(packageData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/packages', packageData);
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updatePackage(packageData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/api/packages/${packageData.id}`, packageData);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deletePackage(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/packages/${id}`);
      if (response.status === 200) dispatch(slice.actions.deletePackageSuccess({ id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPackages() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/packages');
      dispatch(slice.actions.getPackagesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCMSProducts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/packages/cmsproducts');
      dispatch(slice.actions.getCMSProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
