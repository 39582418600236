function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login')
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    create: path(ROOTS_DASHBOARD, '/user/create'),
    detail: path(ROOTS_DASHBOARD, '/user/detail'),
    edit: path(ROOTS_DASHBOARD, '/user/edit'),
    list: path(ROOTS_DASHBOARD, '/user/list')
  },
  partner: {
    root: path(ROOTS_DASHBOARD, '/partner'),
    create: path(ROOTS_DASHBOARD, '/partner/create'),
    detail: path(ROOTS_DASHBOARD, '/partner/detail'),
    edit: path(ROOTS_DASHBOARD, '/partner/edit'),
    list: path(ROOTS_DASHBOARD, '/partner/list')
  },
  movie: {
    root: path(ROOTS_DASHBOARD, '/movie'),
    create: path(ROOTS_DASHBOARD, '/movie/create'),
    detail: path(ROOTS_DASHBOARD, '/movie/detail'),
    edit: path(ROOTS_DASHBOARD, '/movie/edit'),
    list: path(ROOTS_DASHBOARD, '/movie/list')
  },
  series: {
    root: path(ROOTS_DASHBOARD, '/series'),
    create: path(ROOTS_DASHBOARD, '/series/create'),
    detail: path(ROOTS_DASHBOARD, '/series/detail'),
    edit: path(ROOTS_DASHBOARD, '/series/edit'),
    list: path(ROOTS_DASHBOARD, '/series/list')
  },
  season: {
    root: path(ROOTS_DASHBOARD, '/season'),
    create: path(ROOTS_DASHBOARD, '/season/create'),
    detail: path(ROOTS_DASHBOARD, '/season/detail'),
    edit: path(ROOTS_DASHBOARD, '/season/edit')
  },
  episode: {
    root: path(ROOTS_DASHBOARD, '/episode'),
    create: path(ROOTS_DASHBOARD, '/episode/create'),
    detail: path(ROOTS_DASHBOARD, '/episode/detail'),
    edit: path(ROOTS_DASHBOARD, '/episode/edit')
  },
  actor: {
    root: path(ROOTS_DASHBOARD, '/actor'),
    create: path(ROOTS_DASHBOARD, '/actor/create'),
    detail: path(ROOTS_DASHBOARD, '/actor/detail'),
    edit: path(ROOTS_DASHBOARD, '/actor/edit'),
    list: path(ROOTS_DASHBOARD, '/actor/list')
  },
  category: {
    root: path(ROOTS_DASHBOARD, '/category'),
    create: path(ROOTS_DASHBOARD, '/category/create'),
    detail: path(ROOTS_DASHBOARD, '/category/detail'),
    edit: path(ROOTS_DASHBOARD, '/category/edit'),
    list: path(ROOTS_DASHBOARD, '/category/list')
  },
  country: {
    root: path(ROOTS_DASHBOARD, '/country'),
    create: path(ROOTS_DASHBOARD, '/country/create'),
    detail: path(ROOTS_DASHBOARD, '/country/detail'),
    edit: path(ROOTS_DASHBOARD, '/country/edit'),
    list: path(ROOTS_DASHBOARD, '/country/list')
  },
  language: {
    root: path(ROOTS_DASHBOARD, '/language'),
    create: path(ROOTS_DASHBOARD, '/language/create'),
    detail: path(ROOTS_DASHBOARD, '/language/detail'),
    edit: path(ROOTS_DASHBOARD, '/language/edit'),
    list: path(ROOTS_DASHBOARD, '/language/list')
  },
  role: {
    root: path(ROOTS_DASHBOARD, '/role'),
    create: path(ROOTS_DASHBOARD, '/role/create'),
    detail: path(ROOTS_DASHBOARD, '/role/detail'),
    edit: path(ROOTS_DASHBOARD, '/role/edit'),
    list: path(ROOTS_DASHBOARD, '/role/list')
  },
  package: {
    root: path(ROOTS_DASHBOARD, '/package'),
    create: path(ROOTS_DASHBOARD, '/package/create'),
    detail: path(ROOTS_DASHBOARD, '/package/detail'),
    edit: path(ROOTS_DASHBOARD, '/package/edit'),
    list: path(ROOTS_DASHBOARD, '/package/list')
  },
  global: {
    root: path(ROOTS_DASHBOARD, '/global'),
    create: path(ROOTS_DASHBOARD, '/global/create'),
    detail: path(ROOTS_DASHBOARD, '/global/detail'),
    edit: path(ROOTS_DASHBOARD, '/global/edit'),
    list: path(ROOTS_DASHBOARD, '/global/list')
  },
  section: {
    root: path(ROOTS_DASHBOARD, '/section'),
    create: path(ROOTS_DASHBOARD, '/section/create'),
    detail: path(ROOTS_DASHBOARD, '/section/detail'),
    edit: path(ROOTS_DASHBOARD, '/section/edit'),
    list: path(ROOTS_DASHBOARD, '/section/list')
  },
  transcoding: {
    root: path(ROOTS_DASHBOARD, '/transcoding')
  },
  profile: {
    create: path(ROOTS_DASHBOARD, '/transcoding/profile/create'),
    detail: path(ROOTS_DASHBOARD, '/transcoding/profile/detail'),
    edit: path(ROOTS_DASHBOARD, '/transcoding/profile/edit'),
    list: path(ROOTS_DASHBOARD, '/transcoding/')
  },
  quality: {
    create: path(ROOTS_DASHBOARD, '/transcoding/quality/create'),
    detail: path(ROOTS_DASHBOARD, '/transcoding/quality/detail'),
    edit: path(ROOTS_DASHBOARD, '/transcoding/quality/edit'),
    list: path(ROOTS_DASHBOARD, '/transcoding')
  },
  encryption: {
    create: path(ROOTS_DASHBOARD, '/transcoding/encryption/create'),
    detail: path(ROOTS_DASHBOARD, '/transcoding/encryption/detail'),
    edit: path(ROOTS_DASHBOARD, '/transcoding/encryption/edit'),
    list: path(ROOTS_DASHBOARD, '/transcoding')
  },
  zoneConfig: {
    root: path(ROOTS_DASHBOARD, '/zoneconfig')
  },
  zone: {
    create: path(ROOTS_DASHBOARD, '/zoneconfig/zone/create'),
    detail: path(ROOTS_DASHBOARD, '/zoneconfig/zone/detail'),
    edit: path(ROOTS_DASHBOARD, '/zoneconfig/zone/edit'),
    list: path(ROOTS_DASHBOARD, '/zoneconfig/')
  },
  configuration: {
    create: path(ROOTS_DASHBOARD, '/zoneconfig/configuration/create'),
    detail: path(ROOTS_DASHBOARD, '/zoneconfig/configuration/detail'),
    edit: path(ROOTS_DASHBOARD, '/zoneconfig/configuration/edit'),
    list: path(ROOTS_DASHBOARD, '/zoneconfig/')
  },
  paymethod: {
    root: path(ROOTS_DASHBOARD, '/paymethod'),
    create: path(ROOTS_DASHBOARD, '/paymethod/create'),
    detail: path(ROOTS_DASHBOARD, '/paymethod/detail'),
    edit: path(ROOTS_DASHBOARD, '/paymethod/edit'),
    list: path(ROOTS_DASHBOARD, '/paymethod/list')
  }
};
