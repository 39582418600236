import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
      <Box component="img" src="/static/yellow-logo-brand.png" alt="senvod_logo" height={45} />
    </Box>
  );
}
