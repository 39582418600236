import { map, filter, concat } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: 'aaa',
  encryptions: [],
  encryption: null
};

const slice = createSlice({
  name: 'encryption',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload.data.error.message;
    },

    // GET ENCRYPTION
    getEncryptionSuccess(state, action) {
      state.isLoading = false;
      state.error = '';
      state.encryption = action.payload;
    },

    // GET ENCRYPTIONS
    getEncryptionsSuccess(state, action) {
      state.isLoading = false;
      state.error = '';
      state.encryptions = action.payload;
    },

    // CREATE ENCRYPTION
    createEncryptionSuccess(state, action) {
      const addEncryption = concat([action.payload], state.encryptions);
      state.encryptions = addEncryption;
      state.error = '';
    },

    // UPDATE ENCRYPTION
    updateEncryptionSuccess(state, action) {
      const updateEncryption = map(state.encryptions, (encryption) =>
        encryption.id === action.payload.id ? action.payload : encryption
      );
      state.encryptions = updateEncryption;
      state.error = '';
    },

    // DELETE ENCRYPTION
    deleteEncryptionSuccess(state, action) {
      const deleteEncryption = filter(state.encryptions, (encryption) => encryption.id !== action.payload.id);
      state.encryptions = deleteEncryption;
      state.isLoading = false;
      state.error = '';
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getEncryption(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/encryptions/${id}`);
      dispatch(slice.actions.getEncryptionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEncryptions() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/encryptions');
      dispatch(slice.actions.getEncryptionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createEncryption(encryption) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/encryptions', encryption);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateEncryption(encryption) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/api/encryptions/${encryption.id}`, encryption);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteEncryption(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/encryptions/${id}`);
      if (response.status === 200) dispatch(slice.actions.deleteEncryptionSuccess({ id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
