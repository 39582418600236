import { map, filter, concat } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  partners: [],
  partner: null
};

const slice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PARTNER
    getPartnerSuccess(state, action) {
      state.isLoading = false;
      state.partner = action.payload;
    },

    // GET PARTNERS
    getPartnersSuccess(state, action) {
      state.isLoading = false;
      state.partners = action.payload;
    },

    // CREATE PARTNER
    createPartnerSuccess(state, action) {
      const addpartner = concat([action.payload], state.partners);
      state.partners = addpartner;
    },

    // UPDATE PARTNER
    updatePartnerSuccess(state, action) {
      const updatepartner = map(state.partners, (partner) =>
        partner.id === action.payload.id ? action.payload : partner
      );
      state.partners = updatepartner;
    },

    // DELETE PARTNER
    deletePartnerSuccess(state, action) {
      const deletepartner = filter(state.partners, (partner) => partner.id !== action.payload.id);
      state.partners = deletepartner;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPartner(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/partners/${id}`);
      dispatch(slice.actions.getPartnerSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPartners() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/partners');
      dispatch(slice.actions.getPartnersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createPartner(partner, userType) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/partners', partner, {
        headers: {
          UserType: userType
        }
      });
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updatePartner(partner, userType) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/partners/update', partner, {
        headers: {
          UserType: userType
        }
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deletePartner(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/partners/${id}`);
      if (response.status === 200) dispatch(slice.actions.deletePartnerSuccess({ id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
