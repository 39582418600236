import { map, filter, concat } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  profiles: [],
  profile: null
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.profile = action.payload;
    },

    // GET PROFILES
    getProfilesSuccess(state, action) {
      state.isLoading = false;
      state.profiles = action.payload;
    },

    // CREATE PROFILE
    createProfileSuccess(state, action) {
      const addProfile = concat([action.payload], state.profiles);
      state.profiles = addProfile;
    },

    // UPDATE PROFILE
    updateProfileSuccess(state, action) {
      const updateProfile = map(state.profiles, (profile) =>
        profile.id === action.payload.id ? action.payload : profile
      );
      state.profiles = updateProfile;
    },

    // DELETE PROFILE
    deleteProfileSuccess(state, action) {
      const deleteProfile = filter(state.profiles, (profile) => profile.id !== action.payload.id);
      state.profiles = deleteProfile;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getProfile(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/profiles/${id}`);
      dispatch(slice.actions.getProfileSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProfiles() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/profiles');
      dispatch(slice.actions.getProfilesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createProfile(profile) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/profiles', profile);
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateProfile(profile) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/api/profiles/${profile.id}`, profile);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteProfile(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/profiles/${id}`);
      if (response.status === 200) dispatch(slice.actions.deleteProfileSuccess({ id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
