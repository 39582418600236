import PropTypes from 'prop-types';
import { createContext, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';

import NewFormList from '../components/NewFormList';

const initialState = {
  open: false,
  content: null,
  handleOpen: () => {},
  handleContent: () => {}
};

const CreateNewFormDialogContext = createContext(initialState);

CreateNewFormDialogProvider.propTypes = {
  children: PropTypes.node
};

function CreateNewFormDialogProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(null);

  const handleOpen = (value) => {
    setOpen(value);
  };
  const handleContent = (component) => {
    setContent(component);
  };
  let ComponentToRender = '';
  if (content) ComponentToRender = NewFormList[content];
  return (
    <CreateNewFormDialogContext.Provider value={{ open, content, handleOpen, handleContent }}>
      {children}
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg">
        <ComponentToRender />
      </Dialog>
    </CreateNewFormDialogContext.Provider>
  );
}

export { CreateNewFormDialogProvider, CreateNewFormDialogContext };
