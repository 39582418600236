import { map, filter, concat } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: '',
  qualities: [],
  quality: null
};

const slice = createSlice({
  name: 'quality',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload.data.error.message;
    },

    // GET QUALITY
    getQualitySuccess(state, action) {
      state.isLoading = false;
      state.quality = action.payload;
      state.error = '';
    },

    // GET QUALITIES
    getQualitiesSuccess(state, action) {
      state.isLoading = false;
      state.qualities = action.payload;
      state.error = '';
    },

    // CREATE QUALITY
    createQualitySuccess(state, action) {
      const addQuality = concat([action.payload], state.qualities);
      state.qualities = addQuality;
      state.error = '';
    },

    // UPDATE QUALITY
    updateQualitySuccess(state, action) {
      const updateQuality = map(state.qualities, (quality) =>
        quality.id === action.payload.id ? action.payload : quality
      );
      state.qualities = updateQuality;
      state.error = '';
    },

    // DELETE QUALITY
    deleteQualitySuccess(state, action) {
      const deleteQuality = filter(state.qualities, (quality) => quality.id !== action.payload.id);
      state.qualities = deleteQuality;
      state.error = '';
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getQuality(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/qualities/${id}`);
      dispatch(slice.actions.getQualitySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getQualities() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/qualities');
      dispatch(slice.actions.getQualitiesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createQuality(quality) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/qualities', quality);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateQuality(quality) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/api/qualities/${quality.id}`, quality);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteQuality(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/qualities/${id}`);
      if (response.status === 200) dispatch(slice.actions.deleteQualitySuccess({ id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
