import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import Box from '@material-ui/core/Box';

const Page = forwardRef(({ children, ...other }, ref) => (
  <Box ref={ref} {...other}>
    {children}
  </Box>
));

Page.propTypes = {
  children: PropTypes.node.isRequired
};

export default Page;
