import { map, filter, concat } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: false,
  paymethods: [],
  paymethod: null,
  paysystems: []
};

const slice = createSlice({
  name: 'paymethod',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PAYMETHOD
    getPaymethodSuccess(state, action) {
      state.isLoading = false;
      state.paymethod = action.payload;
    },

    // GET PAYMETHODS
    getPaymethodsSuccess(state, action) {
      state.isLoading = false;
      state.paymethods = action.payload;
    },

    // GET PAYMETHODS
    getPaysystemsSuccess(state, action) {
      state.isLoading = false;
      state.paysystems = action.payload;
    },

    // CREATE PAYMETHOD
    createPaymethodSuccess(state, action) {
      const newPaymethods = concat([action.payload], state.paymethods);
      state.paymethods = newPaymethods;
    },

    // UPDATE PAYMETHOD
    updatePaymethodSuccess(state, action) {
      const newPaymethods = map(state.paymethods, (paymethod) =>
        paymethod.id === action.payload.id ? action.payload : paymethod
      );
      state.paymethods = newPaymethods;
    },

    // DELETE PAYMETHOD
    deletePaymethodSuccess(state, action) {
      const newPaymethods = filter(state.paymethods, (paymethod) => paymethod.id !== action.payload.id);
      state.paymethods = newPaymethods;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPaymethod(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/paymethods/${id}`);
      dispatch(slice.actions.getPaymethodSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPaymethods() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/paymethods');
      dispatch(slice.actions.getPaymethodsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createPaymethod(paymethod) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/paymethods', paymethod);
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updatePaymethod(paymethod) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`/api/paymethods/update`, paymethod);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deletePaymethod(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/paymethods/${id}`);
      if (response.status === 200) dispatch(slice.actions.deletePaymethodSuccess({ id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPaysystems() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/paysystems');
      dispatch(slice.actions.getPaysystemsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
