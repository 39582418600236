import { lazy } from 'react';

const NewFormList = {};
NewFormList.ActorNewForm = lazy(() => import('./_dashboard/actor/ActorNewForm'));
NewFormList.CategoryNewForm = lazy(() => import('./_dashboard/category/CategoryNewForm'));
NewFormList.CountryNewForm = lazy(() => import('./_dashboard/country/CountryNewForm'));
NewFormList.LanguageNewForm = lazy(() => import('./_dashboard/language/LanguageNewForm'));
NewFormList.MovieNewForm = lazy(() => import('./_dashboard/movie/MovieNewForm'));
NewFormList.PackageNewForm = lazy(() => import('./_dashboard/package/PackageNewForm'));
NewFormList.PartnerNewForm = lazy(() => import('./_dashboard/partner/PartnerNewForm'));
NewFormList.RoleNewForm = lazy(() => import('./_dashboard/role/RoleNewForm'));

export default NewFormList;
