// material
import { styled } from '@material-ui/core/styles';
import { Box, Container } from '@material-ui/core';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import { LoginForm } from '../../components/authentication/login';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Admin Login | SenVOD">
      <Container maxWidth="sm">
        <ContentStyle>
          <Box display="flex" justifyContent="center" sx={{ mb: 5 }}>
            <Logo />
          </Box>
          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
