import { map, filter, concat } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  movies: [],
  movie: null
};

const slice = createSlice({
  name: 'movie',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MOVIE
    getMovieSuccess(state, action) {
      state.isLoading = false;
      state.movie = action.payload;
    },

    // GET MOVIES
    getMoviesSuccess(state, action) {
      state.isLoading = false;
      state.movies = action.payload;
    },

    // CREATE MOVIE
    createMovieSuccess(state, action) {
      const addMovie = concat([action.payload], state.movies);
      state.movies = addMovie;
    },

    // UPDATE MOVIE
    updateMovieSuccess(state, action) {
      const updateMovie = map(state.movies, (movie) => (movie.id === action.payload.id ? action.payload : movie));
      state.movies = updateMovie;
    },

    // DELETE MOVIE
    deleteMovieSuccess(state, action) {
      const deleteMovie = filter(state.movies, (movie) => movie.id !== action.payload.id);
      state.movies = deleteMovie;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getMovie(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/movies/${id}`);
      dispatch(slice.actions.getMovieSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMovies() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/movies');
      dispatch(slice.actions.getMoviesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createMovie(movie) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/movies', movie, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateMovie(movie) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/movies/update', movie);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteMovie(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/movies/${id}`);
      if (response.status === 200) dispatch(slice.actions.deleteMovieSuccess({ id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
