import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import categoryReducer from './slices/category';
import countryReducer from './slices/country';
import languageReducer from './slices/language';
import roleReducer from './slices/role';
import sectionReducer from './slices/section';
import permissionReducer from './slices/permission';
import partnerReducer from './slices/partner';
import actorReducer from './slices/actor';
import movieReducer from './slices/movie';
import packageReducer from './slices/package';
import seriesReducer from './slices/series';
import seasonReducer from './slices/season';
import episodeReducer from './slices/episode';
import globalReducer from './slices/global';
import dashboardReducer from './slices/dashboard';
import profileReducer from './slices/profile';
import encryptionReducer from './slices/encryption';
import qualityReducer from './slices/quality';
import zoneReducer from './slices/zone';
import configurationReducer from './slices/configuration';
import paymethodReducer from './slices/paymethod';
// import productReducer from './slices/product';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

// const productPersistConfig = {
//   key: 'product',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['sortBy', 'checkout']
// };

const rootReducer = combineReducers({
  user: userReducer,
  category: categoryReducer,
  country: countryReducer,
  language: languageReducer,
  section: sectionReducer,
  role: roleReducer,
  permission: permissionReducer,
  partner: partnerReducer,
  actor: actorReducer,
  movie: movieReducer,
  package: packageReducer,
  series: seriesReducer,
  season: seasonReducer,
  episode: episodeReducer,
  global: globalReducer,
  profile: profileReducer,
  encryption: encryptionReducer,
  quality: qualityReducer,
  zone: zoneReducer,
  configuration: configurationReducer,
  dashboard: dashboardReducer,
  paymethod: paymethodReducer
  // product: persistReducer(productPersistConfig, productReducer)
});

export { rootPersistConfig, rootReducer };
