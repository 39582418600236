import { map, filter, concat } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  seriesList: [],
  series: null
};

const slice = createSlice({
  name: 'series',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET SERIES_LIST
    getSeriesListSuccess(state, action) {
      state.isLoading = false;
      state.seriesList = action.payload;
    },

    // GET SERIES
    getSeriesSuccess(state, action) {
      state.isLoading = false;
      state.series = action.payload;
    },

    // CREATE SERIES
    createSeriesSuccess(state, action) {
      const addSeries = concat([action.payload], state.seriesList);
      state.seriesList = addSeries;
    },

    // UPDATE SERIES
    updateSeriesSuccess(state, action) {
      const updateSeries = map(state.seriesList, (series) =>
        series.id === action.payload.id ? action.payload : series
      );
      state.seriesList = updateSeries;
    },

    // DELETE SERIES
    deleteSeriesSuccess(state, action) {
      const deleteSeries = filter(state.seriesList, (series) => series.id !== action.payload.id);
      state.seriesList = deleteSeries;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getSeriesList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/series');
      dispatch(slice.actions.getSeriesListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSeries(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/series/${id}`);
      dispatch(slice.actions.getSeriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createSeries(series) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/series', series, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateSeries(series) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/series/update', series);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteSeries(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/series/${id}`);
      if (response.status === 200) dispatch(slice.actions.deleteSeriesSuccess({ id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
