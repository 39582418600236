import { map, filter, concat } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: false,
  actors: [],
  actor: null
};

const slice = createSlice({
  name: 'actor',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ACTOR
    getActorSuccess(state, action) {
      state.isLoading = false;
      state.actor = action.payload;
    },

    // GET ACTORS
    getActorsSuccess(state, action) {
      state.isLoading = false;
      state.actors = action.payload;
    },

    // CREATE ACTOR
    createActorSuccess(state, action) {
      const addActor = concat([action.payload], state.actors);
      state.actors = addActor;
    },

    // UPDATE ACTOR
    updateActorSuccess(state, action) {
      const updateActor = map(state.actors, (actor) => (actor.id === action.payload.id ? action.payload : actor));
      state.actors = updateActor;
    },

    // DELETE ACTOR
    deleteActorSuccess(state, action) {
      const deleteActor = filter(state.actors, (actor) => actor.id !== action.payload.id);
      state.actors = deleteActor;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getActor(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/actors/${id}`);
      dispatch(slice.actions.getActorSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getActors() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/actors');
      dispatch(slice.actions.getActorsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createActor(actor) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/actors', actor);
    } catch (error) {
      // console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateActor(actor) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/actors/update', actor);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteActor(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/actors/${id}`);
      if (response.status === 200) dispatch(slice.actions.deleteActorSuccess({ id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
