// material
import PublicIcon from '@material-ui/icons/Public';
import CategoryIcon from '@material-ui/icons/Category';
import LanguageIcon from '@material-ui/icons/Language';
import EditRoadIcon from '@material-ui/icons/EditRoad';
import BallotIcon from '@material-ui/icons/Ballot';
import MovieIcon from '@material-ui/icons/Movie';
import LocalMovieIcon from '@material-ui/icons/LocalMovies';
import SettingsIcon from '@material-ui/icons/Settings';
import PaidIcon from '@material-ui/icons/Paid';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  user: getIcon('ic_user'),
  movie: <MovieIcon />,
  series: <LocalMovieIcon />,
  season: <LocalMovieIcon />,
  episode: <MovieIcon />,
  category: <CategoryIcon />,
  country: <PublicIcon />,
  language: <LanguageIcon />,
  role: <EditRoadIcon />,
  package: <BallotIcon />,
  global: <SettingsIcon />,
  paymethod: <PaidIcon />
};

const sidebarConfig = [
  {
    title: 'Dashboard',
    path: PATH_DASHBOARD.general.app,
    icon: ICONS.dashboard
  },
  // USER
  {
    title: 'Users',
    path: PATH_DASHBOARD.user.root,
    icon: ICONS.user,
    children: [
      { title: 'user list', path: PATH_DASHBOARD.user.list },
      { title: 'add user', path: PATH_DASHBOARD.user.create }
    ]
  },
  // PARTNER
  {
    title: 'Publishers',
    path: PATH_DASHBOARD.partner.root,
    icon: ICONS.user,
    children: [
      { title: 'partner list', path: PATH_DASHBOARD.partner.list },
      { title: 'add partner', path: PATH_DASHBOARD.partner.create }
    ]
  },
  // MOVIE
  {
    title: 'Movies',
    path: PATH_DASHBOARD.movie.root,
    icon: ICONS.movie,
    children: [
      { title: 'movie list', path: PATH_DASHBOARD.movie.list },
      { title: 'add movie', path: PATH_DASHBOARD.movie.create }
    ]
  },
  // SERIES
  {
    title: 'TV Series',
    path: PATH_DASHBOARD.series.root,
    icon: ICONS.series,
    children: [
      { title: 'series list', path: PATH_DASHBOARD.series.list },
      { title: 'add series', path: PATH_DASHBOARD.series.create }
    ]
  },
  // ACTOR
  {
    title: 'Actors',
    path: PATH_DASHBOARD.actor.root,
    icon: ICONS.user,
    children: [
      { title: 'actor list', path: PATH_DASHBOARD.actor.list },
      { title: 'add actor', path: PATH_DASHBOARD.actor.create }
    ]
  },
  // Category
  {
    title: 'Genres',
    path: PATH_DASHBOARD.category.root,
    icon: ICONS.category,
    children: [
      { title: 'category list', path: PATH_DASHBOARD.category.list },
      { title: 'add category', path: PATH_DASHBOARD.category.create }
    ]
  },
  // Country
  {
    title: 'Countries',
    path: PATH_DASHBOARD.country.root,
    icon: ICONS.country,
    children: [
      { title: 'country list', path: PATH_DASHBOARD.country.list },
      { title: 'add country', path: PATH_DASHBOARD.country.create }
    ]
  },
  // Language
  {
    title: 'Languages',
    path: PATH_DASHBOARD.language.root,
    icon: ICONS.language,
    children: [
      { title: 'language list', path: PATH_DASHBOARD.language.list },
      { title: 'add language', path: PATH_DASHBOARD.language.create }
    ]
  },
  // Role
  {
    title: 'Roles',
    path: PATH_DASHBOARD.role.root,
    icon: ICONS.role,
    children: [
      { title: 'role list', path: PATH_DASHBOARD.role.list },
      { title: 'add role', path: PATH_DASHBOARD.role.create }
    ]
  },
  // Package
  {
    title: 'Packages',
    path: PATH_DASHBOARD.package.root,
    icon: ICONS.package,
    children: [
      { title: 'package list', path: PATH_DASHBOARD.package.list },
      { title: 'add package', path: PATH_DASHBOARD.package.create }
    ]
  },
  // Paymethod
  {
    title: 'Paymethods',
    path: PATH_DASHBOARD.paymethod.root,
    icon: ICONS.paymethod,
    children: [
      { title: 'paymethod list', path: PATH_DASHBOARD.paymethod.list },
      { title: 'add paymethod', path: PATH_DASHBOARD.paymethod.create }
    ]
  },
  // Global Settings
  {
    title: 'Global Settings',
    path: PATH_DASHBOARD.global.root,
    icon: ICONS.global,
    children: [
      { title: 'global list', path: PATH_DASHBOARD.global.list },
      { title: 'add global', path: PATH_DASHBOARD.global.create }
    ]
  },
  {
    title: 'Sections',
    path: PATH_DASHBOARD.section.root,
    icon: ICONS.package,
    children: [
      { title: 'section list', path: PATH_DASHBOARD.section.list },
      { title: 'add section', path: PATH_DASHBOARD.section.create }
    ]
  },
  // Transcodings(profiles, qualities, encryptions)
  {
    title: 'Transcodings',
    path: PATH_DASHBOARD.transcoding.root,
    icon: ICONS.global
  },
  // Zone & Config(zones, configurations)
  {
    title: 'ZoneConfigurations',
    path: PATH_DASHBOARD.zoneConfig.root,
    icon: ICONS.global
  }
];

export default sidebarConfig;
