import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import useAuth from '../hooks/useAuth';
import LoadingScreen from '../components/LoadingScreen';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { user } = useAuth();
  const allRoutes = [
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        }
      ]
    },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', title: 'Dashboard', element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', title: 'Dashboard', element: <GeneralApp /> },
        {
          path: 'user',
          title: 'Users',
          children: [
            { path: '', element: <Navigate to="/dashboard/user/list" replace /> },
            { path: 'list', element: <UserList /> },
            { path: 'detail/:userId', element: <UserCreate /> },
            { path: 'create', element: <UserCreate /> },
            { path: 'edit/:userId', element: <UserCreate /> }
          ]
        },
        {
          path: 'partner',
          title: 'Publishers',
          children: [
            { path: '', element: <Navigate to="/dashboard/partner/list" replace /> },
            { path: 'list', element: <PartnerList /> },
            { path: 'detail/:partnerId', element: <PartnerCreate /> },
            { path: 'create', element: <PartnerCreate /> },
            { path: 'edit/:partnerId', element: <PartnerCreate /> }
          ]
        },
        {
          path: 'movie',
          title: 'Movies',
          children: [
            { path: '', element: <Navigate to="/dashboard/movie/list" replace /> },
            { path: 'list', element: <MovieList /> },
            { path: 'detail/:movieId', element: <MovieCreate /> },
            { path: 'create', element: <MovieCreate /> },
            { path: 'edit/:movieId', element: <MovieCreate /> }
          ]
        },
        {
          path: 'series',
          title: 'TV Series',
          children: [
            { path: '', element: <Navigate to="/dashboard/series/list" replace /> },
            { path: 'list', element: <SeriesList /> },
            { path: 'create', element: <SeriesCreate /> },
            { path: 'edit/:seriesId', element: <SeriesCreate /> },
            { path: ':seriesId/seasons/list', element: <SeasonList /> },
            { path: ':seriesId/seasons/create', element: <SeasonCreate /> },
            { path: ':seriesId/seasons/edit/:seasonId', element: <SeasonCreate /> },
            { path: ':seriesId/seasons/:seasonId/episodes/create', element: <EpisodeCreate /> },
            { path: ':seriesId/seasons/:seasonId/episodes/edit/:episodeId', element: <EpisodeCreate /> }
          ]
        },
        {
          path: 'actor',
          title: 'Actors',
          children: [
            { path: '', element: <Navigate to="/dashboard/actor/list" replace /> },
            { path: 'list', element: <ActorList /> },
            { path: 'detail/:actorId', element: <ActorCreate /> },
            { path: 'create', element: <ActorCreate /> },
            { path: 'edit/:actorId', element: <ActorCreate /> }
          ]
        },
        {
          path: 'category',
          title: 'Genres',
          children: [
            { path: '', element: <Navigate to="/dashboard/category/list" replace /> },
            { path: 'list', element: <CategoryList /> },
            { path: 'detail/:categoryId', element: <CategoryCreate /> },
            { path: 'create', element: <CategoryCreate /> },
            { path: 'edit/:categoryId', element: <CategoryCreate /> }
          ]
        },
        {
          path: 'country',
          title: 'Countries',
          children: [
            { path: '', element: <Navigate to="/dashboard/country/list" replace /> },
            { path: 'list', element: <CountryList /> },
            { path: 'detail/:countryId', element: <CountryCreate /> },
            { path: 'create', element: <CountryCreate /> },
            { path: 'edit/:countryId', element: <CountryCreate /> }
          ]
        },
        {
          path: 'language',
          title: 'Languages',
          children: [
            { path: '', element: <Navigate to="/dashboard/language/list" replace /> },
            { path: 'list', element: <LanguageList /> },
            { path: 'detail/:languageId', element: <LanguageCreate /> },
            { path: 'create', element: <LanguageCreate /> },
            { path: 'edit/:languageId', element: <LanguageCreate /> }
          ]
        },
        {
          path: 'role',
          title: 'Roles',
          children: [
            { path: '', element: <Navigate to="/dashboard/role/list" replace /> },
            { path: 'list', element: <RoleList /> },
            { path: 'detail/:roleId', element: <RoleCreate /> },
            { path: 'create', element: <RoleCreate /> },
            { path: 'edit/:roleId', element: <RoleCreate /> }
          ]
        },
        {
          path: 'package',
          title: 'Packages',
          children: [
            { path: '', element: <Navigate to="/dashboard/package/list" replace /> },
            { path: 'list', element: <PackageList /> },
            { path: 'detail/:packageId', element: <PackageCreate /> },
            { path: 'create', element: <PackageCreate /> },
            { path: 'edit/:packageId', element: <PackageCreate /> }
          ]
        },
        {
          path: 'global',
          title: 'Global Settings',
          children: [
            { path: '', element: <Navigate to="/dashboard/global/list" replace /> },
            { path: 'list', element: <GlobalList /> },
            { path: 'detail/:globalId', element: <GlobalCreate /> },
            { path: 'create', element: <GlobalCreate /> },
            { path: 'edit/:globalId', element: <GlobalCreate /> }
          ]
        },
        {
          path: 'section',
          title: 'Sections',
          children: [
            { path: '', element: <Navigate to="/dashboard/section/list" replace /> },
            { path: 'list', element: <SectionList /> },
            { path: 'detail/:sectionId', element: <Section /> },
            { path: 'create', element: <Section /> },
            { path: 'edit/:sectionId', element: <Section /> }
          ]
        },
        {
          path: 'transcoding',
          title: 'Transcodings',
          children: [
            { path: '', element: <TranscodingPage /> },
            { path: 'profile/create', element: <ProfileCreate /> },
            { path: 'profile/detail/:profileId', element: <ProfileCreate /> },
            { path: 'profile/edit/:profileId', element: <ProfileCreate /> },
            { path: 'quality/create', element: <Quality /> },
            { path: 'quality/detail/:qualityId', element: <Quality /> },
            { path: 'quality/edit/:qualityId', element: <Quality /> },
            { path: 'encryption/create', element: <Encryption /> },
            { path: 'encryption/detail/:encryptionId', element: <Encryption /> },
            { path: 'encryption/edit/:encryptionId', element: <Encryption /> }
          ]
        },
        {
          path: 'zoneconfig',
          title: 'ZoneConfigurations',
          children: [
            { path: '', element: <ZoneConfigurationPage /> },
            { path: 'zone/create', element: <Zone /> },
            { path: 'zone/detail/:zoneId', element: <Zone /> },
            { path: 'zone/edit/:zoneId', element: <Zone /> },
            { path: 'configuration/create', element: <Configuration /> },
            { path: 'configuration/detail/:configurationId', element: <Configuration /> },
            { path: 'configuration/edit/:configurationId', element: <Configuration /> }
          ]
        },
        {
          path: 'paymethod',
          title: 'Paymethods',
          children: [
            { path: '', element: <Navigate to="/dashboard/paymethod/list" replace /> },
            { path: 'list', element: <PaymethodList /> },
            { path: 'detail/:paymethodId', element: <Paymethod /> },
            { path: 'create', element: <Paymethod /> },
            { path: 'edit/:paymethodId', element: <Paymethod /> }
          ]
        }
      ]
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> },
    { path: '/', element: <Navigate to="/dashboard" replace /> }
  ];

  const filterRoutes = () => {
    if (user) {
      const allowedPermissions = user.accessPermissions.filter((item) => item.permissions.length > 0);
      const filteredRoutes = [];
      allowedPermissions.forEach((permission) => {
        if (permission.name === 'Dashboard') {
          filteredRoutes.push(allRoutes[1].children[0], allRoutes[1].children[1]);
        } else {
          const menuItem = allRoutes[1].children.find((item) => permission.name === item.title);
          if (menuItem) {
            if (
              menuItem.children &&
              menuItem.title !== 'TV Series' &&
              menuItem.title !== 'Transcodings' &&
              menuItem.title !== 'ZoneConfigurations'
            ) {
              const newChildren = [];
              const listChecked = permission.permissions.find((item) => item.name === 'List').checked;
              if (listChecked === 'true') newChildren.push(menuItem.children[1]);

              const detailChecked = permission.permissions.find((item) => item.name === 'View').checked;
              if (detailChecked === 'true') newChildren.push(menuItem.children[2]);

              const createChecked = permission.permissions.find((item) => item.name === 'Create').checked;
              if (createChecked === 'true') newChildren.push(menuItem.children[3]);

              const updateChecked = permission.permissions.find((item) => item.name === 'Update').checked;
              if (updateChecked === 'true') newChildren.push(menuItem.children[4]);

              menuItem.children = newChildren;
            }
            filteredRoutes.push(menuItem);
          }
        }
      });
      allRoutes[1].children = filteredRoutes;
    } else allRoutes[1].children = [];
    return allRoutes;
  };
  return useRoutes(filterRoutes());
}

// IMPORT COMPONENTS
const Page500 = Loadable(lazy(() => import('src/pages/Page500')));
const NotFound = Loadable(lazy(() => import('src/pages/Page404')));
// Authentication
const Login = Loadable(lazy(() => import('src/pages/authentication/Login')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('src/pages/dashboard/GeneralApp')));
// User
const UserList = Loadable(lazy(() => import('src/pages/dashboard/user/UserList')));
const UserCreate = Loadable(lazy(() => import('src/pages/dashboard/user/UserCreate')));
// Partner
const PartnerList = Loadable(lazy(() => import('src/pages/dashboard/partner/PartnerList')));
const PartnerCreate = Loadable(lazy(() => import('src/pages/dashboard/partner/PartnerCreate')));
// Actor
const ActorList = Loadable(lazy(() => import('src/pages/dashboard/actor/ActorList')));
const ActorCreate = Loadable(lazy(() => import('src/pages/dashboard/actor/ActorCreate')));
// Movie
const MovieList = Loadable(lazy(() => import('src/pages/dashboard/movie/MovieList')));
const MovieCreate = Loadable(lazy(() => import('src/pages/dashboard/movie/MovieCreate')));
// Series
const SeriesList = Loadable(lazy(() => import('src/pages/dashboard/series/SeriesList')));
const SeriesCreate = Loadable(lazy(() => import('src/pages/dashboard/series/SeriesCreate')));
// Season
const SeasonList = Loadable(lazy(() => import('src/pages/dashboard/season/SeasonList')));
const SeasonCreate = Loadable(lazy(() => import('src/pages/dashboard/season/SeasonCreate')));
// Episode
const EpisodeCreate = Loadable(lazy(() => import('src/pages/dashboard/episode/EpisodeCreate')));
// Category
const CategoryList = Loadable(lazy(() => import('src/pages/dashboard/category/CategoryList')));
const CategoryCreate = Loadable(lazy(() => import('src/pages/dashboard/category/CategoryCreate')));
// Country
const CountryList = Loadable(lazy(() => import('src/pages/dashboard/country/CountryList')));
const CountryCreate = Loadable(lazy(() => import('src/pages/dashboard/country/CountryCreate')));
// Language
const LanguageList = Loadable(lazy(() => import('src/pages/dashboard/language/LanguageList')));
const LanguageCreate = Loadable(lazy(() => import('src/pages/dashboard/language/LanguageCreate')));
// Role
const RoleList = Loadable(lazy(() => import('src/pages/dashboard/role/RoleList')));
const RoleCreate = Loadable(lazy(() => import('src/pages/dashboard/role/RoleCreate')));
// Package
const PackageList = Loadable(lazy(() => import('src/pages/dashboard/package/PackageList')));
const PackageCreate = Loadable(lazy(() => import('src/pages/dashboard/package/PackageCreate')));
// Global
const GlobalList = Loadable(lazy(() => import('src/pages/dashboard/global/GlobalList')));
const GlobalCreate = Loadable(lazy(() => import('src/pages/dashboard/global/GlobalCreate')));
// Section
const SectionList = Loadable(lazy(() => import('src/pages/dashboard/section/SectionList')));
const Section = Loadable(lazy(() => import('src/pages/dashboard/section/Section')));
// Transcoding
const TranscodingPage = Loadable(lazy(() => import('src/pages/dashboard/transcoding/index')));
const ProfileCreate = Loadable(lazy(() => import('src/pages/dashboard/transcoding/profile/ProfileCreate')));
const Quality = Loadable(lazy(() => import('src/pages/dashboard/transcoding/quality/Quality')));
const Encryption = Loadable(lazy(() => import('src/pages/dashboard/transcoding/encryption/Encryption')));
// Zone & Configuration
const ZoneConfigurationPage = Loadable(lazy(() => import('src/pages/dashboard/zone_config/index')));
const Zone = Loadable(lazy(() => import('src/pages/dashboard/zone_config/zone/Zone')));
const Configuration = Loadable(lazy(() => import('src/pages/dashboard/zone_config/configuration/Configuration')));
// Paymethod
const PaymethodList = Loadable(lazy(() => import('src/pages/dashboard/paymethod/PaymethodList')));
const Paymethod = Loadable(lazy(() => import('src/pages/dashboard/paymethod/Paymethod')));
