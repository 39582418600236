import { map, filter, concat } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  globals: [],
  global: null
};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET GLOBALS
    getGlobalsSuccess(state, action) {
      state.isLoading = false;
      state.globals = action.payload;
    },

    // GET GLOBAL
    getGlobalSuccess(state, action) {
      state.isLoading = false;
      state.global = action.payload;
    },

    // CREATE GLOBAL
    createGlobalSuccess(state, action) {
      const addGlobal = concat([action.payload], state.globals);
      state.globals = addGlobal;
    },

    // UPDATE GLOBAL
    updateGlobalSuccess(state, action) {
      const updateGlobal = map(state.globals, (global) => (global.id === action.payload.id ? action.payload : global));
      state.globals = updateGlobal;
    },

    // DELETE GLOBAL
    deleteGlobalSuccess(state, action) {
      const deleteGlobal = filter(state.globals, (global) => global.id !== action.payload.id);
      state.globals = deleteGlobal;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getGlobals() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/globals');
      dispatch(slice.actions.getGlobalsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGlobal(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/globals/${id}`);
      dispatch(slice.actions.getGlobalSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createGlobal(global) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/globals', global);
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateGlobal(global) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/api/globals/${global.id}`, global);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteGlobal(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/globals/${id}`);
      if (response.status === 200) dispatch(slice.actions.deleteGlobalSuccess({ id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
