import { map, filter, concat } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  countries: [],
  country: null
};

const slice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET COUNTRY
    getCountrySuccess(state, action) {
      state.isLoading = false;
      state.country = action.payload;
    },

    // GET COUNTRIES
    getCountriesSuccess(state, action) {
      state.isLoading = false;
      state.countries = action.payload;
    },

    // CREATE COUNTRY
    createCountrySuccess(state, action) {
      const addCountry = concat([action.payload], state.countries);
      state.countries = addCountry;
    },

    // UPDATE COUNTRY
    updateCountrySuccess(state, action) {
      const updateCountry = map(state.countries, (country) =>
        country.id === action.payload.id ? action.payload : country
      );
      state.countries = updateCountry;
    },

    // DELETE COUNTRY
    deleteCountrySuccess(state, action) {
      const deleteCountry = filter(state.countries, (country) => country.id !== action.payload.id);
      state.countries = deleteCountry;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCountry(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/countries/${id}`);
      dispatch(slice.actions.getCountrySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCountries() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/countries');
      dispatch(slice.actions.getCountriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createCountry(country) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/countries', country);
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateCountry(country) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/api/countries/${country.id}`, country);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteCountry(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/countries/${id}`);
      if (response.status === 200) dispatch(slice.actions.deleteCountrySuccess({ id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
