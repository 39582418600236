import { map, filter, concat } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: '',
  zones: [],
  zone: null
};

const slice = createSlice({
  name: 'zone',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload.data.error.message;
    },

    // GET ZONE
    getZoneSuccess(state, action) {
      state.isLoading = false;
      state.zone = action.payload;
    },

    // GET ZONES
    getZonesSuccess(state, action) {
      state.isLoading = false;
      state.zones = action.payload;
    },

    // CREATE ZONE
    createZoneSuccess(state, action) {
      const addZone = concat([action.payload], state.zones);
      state.zones = addZone;
    },

    // UPDATE ZONE
    updateZoneSuccess(state, action) {
      const updateZone = map(state.zones, (zone) => (zone.id === action.payload.id ? action.payload : zone));
      state.zones = updateZone;
    },

    // DELETE ZONE
    deleteZoneSuccess(state, action) {
      const deleteZone = filter(state.zones, (zone) => zone.id !== action.payload.id);
      state.zones = deleteZone;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getZone(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/zones/${id}`);
      dispatch(slice.actions.getZoneSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getZones() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/zones');
      dispatch(slice.actions.getZonesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createZone(zone) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/zones', zone);
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateZone(zone) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/api/zones/${zone.id}`, zone);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteZone(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/zones/${id}`);
      if (response.status === 200) dispatch(slice.actions.deleteZoneSuccess({ id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
