import { map, filter, concat } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  sections: [],
  section: null
};

const slice = createSlice({
  name: 'section',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET SECTIONS
    getSectionsSuccess(state, action) {
      state.isLoading = false;
      state.sections = action.payload;
    },

    // GET SECTION
    getSectionSuccess(state, action) {
      state.isLoading = false;
      state.section = action.payload;
    },

    // CREATE SECTION
    createSectionSuccess(state, action) {
      const addSection = concat([action.payload], state.sections);
      state.sections = addSection;
    },

    // UPDATE SECTION
    updateSectionSuccess(state, action) {
      const updateSection = map(state.sections, (section) =>
        section.id === action.payload.id ? action.payload : section
      );
      state.sections = updateSection;
    },

    // DELETE SECTION
    deleteSectionSuccess(state, action) {
      const deleteSection = filter(state.sections, (section) => section.id !== action.payload.id);
      state.sections = deleteSection;
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

export function getSections() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/sections');
      dispatch(slice.actions.getSectionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSection(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/sections/${id}`);
      dispatch(slice.actions.getSectionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createSection(section) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/sections', section);
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateSection(section) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/api/sections/${section.id}`, section);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteSection(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/sections/${id}`);
      if (response.status === 200) dispatch(slice.actions.deleteSectionSuccess({ id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
